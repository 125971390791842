<section class="p-5 m-5 bg-warning text-dark rounded-3">
    <div
      class="container min-vh-50 py-6 d-flex justify-content-center align-items-center"
      style="max-width: 1920px"
    >
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="lc-block mb-4">
            <div editable="rich">
              <h1 class="fw-bold display-1">404</h1>
            </div>
          </div>
          <div class="lc-block">
            <div editable="rich">
              <p class="h2">
                Lo sentimos, no podemos encontrar la página que estás buscando.
              </p>
              <p class="lead">
                Haga clic en el botón de abajo para volver a la página de inicio
              </p>
            </div>
          </div>
          <div class="lc-block">
            <a class="btn btn-primary" href="/" role="button">Inicio</a>
          </div>
        </div>
      </div>
    </div>
  </section>