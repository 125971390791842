import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

  isMobile(): boolean {
    return window.innerWidth > 300;
  }
  imagePath: string = '/assets/imagenes/camilo.png';
  imagePathLogoSoprateria: string = '/assets/imagenes/soprasteria_logo.jpeg';
  imagePathLogoCandemasjor: string = '/assets/imagenes/logo_candemasjor.png';
  imagePathLogoRehubik: string = '/assets/imagenes/logo-2.png';
  imagePathLogoFesord: string = '/assets/imagenes/fesord.jpeg';
  imagePathLogoCerda: string = '/assets/imagenes/306976098_524576413002967_9144824536959535549_n.jpg';
  imagePathLogoAppinformatico: string = '/assets/imagenes/appinformatico.jpeg';
  imagePathLogoRaylight: string = '/assets/imagenes/raylight.png';
  imagePathLogoLaplazanet: string = '/assets/imagenes/laplazanet.png';
  imagePathLogoHachcomunicacion: string = '/assets/imagenes/hachecomunicacion.png';
  imagePathLogoNevacom: string = '/assets/imagenes/nevacom.png';
}
