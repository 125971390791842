<section class="p-2 my-5 bg-warning text-dark rounded-3 text-center">
  <h1>Currículum vitae</h1>
  <img
    [ngClass]="{ 'mobile-image': !isMobile(), 'desktop-image': isMobile() }"
    [src]="imagePath"
    class="rounded-circle"
  />
  <p class="mt-3 mb-0">Camilo Mascarell Jorques</p>
  <p class="mb-0">camilomascarelljorques&#64;gmail.com</p>
  <p class="mb-0">Desarrollo web, informática, seguridad, programación, etc.</p>
</section>
<section class="p-2 my-5 bg-warning text-dark rounded-3 text-center">
  <h1>Mi video</h1>
  <div class="iframe-container">
    <iframe
      width="100%"
      height="440"
      src="https://www.youtube-nocookie.com/embed/MYas1jWzJqs?cc_load_policy=1&cc_lang_pref=es"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</section>
<section id="experiencia" class="container-fluid bg-warning text-dark rounded-3 text-center">
  <h1>Experiencia</h1>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-4 m-0 mb-2">
        <div class="bg-light rounded-3 p-2">
          <img
            [ngClass]="{
              'mobile-image': !isMobile(),
              'desktop-image': isMobile()
            }"
            [src]="imagePathLogoSoprateria"
            class="rounded-circle border border-dark"
          />
          <p class="mb-0 fw-bolder">Junio 2022 - Febrero 2024</p>
          <p class="mb-0">Junior Developer</p>
          <p class="mb-0">Jornada completa</p>
          <p class="mb-0 fs-6">València, Comunitat Valenciana, España</p>
        </div>
      </div>
      <div class="col-lg-4 m-0 mb-2">
        <div class="bg-light rounded-3 p-2">
          <img
            [ngClass]="{
              'mobile-image': !isMobile(),
              'desktop-image': isMobile()
            }"
            [src]="imagePathLogoSoprateria"
            class="rounded-circle border border-dark"
          />
          <p class="mb-0 fw-bolder">Junio 2023 -  Octubre 2023</p>
          <p class="mb-0">Desarrollador de front-end "myX"</p>
          <p class="mb-0">Jornada completa</p>
          <p class="mb-0 fs-6">València, Comunitat Valenciana, España</p>
        </div>
      </div>
      <div class="col-lg-4 m-0 mb-2">
        <div class="bg-light rounded-3 p-2">
          <img
            [ngClass]="{
              'mobile-image': !isMobile(),
              'desktop-image': isMobile()
            }"
            [src]="imagePathLogoSoprateria"
            class="rounded-circle border border-dark"
          />
          <p class="mb-0 fw-bolder">Marzo 2022 - Mayo 2022 </p>
          <p class="mb-0">Desarrollador</p>
          <p class="mb-0">Contrato de prácticas</p>
          <p class="mb-0 fs-6">València, Comunitat Valenciana, España</p>
      </div>
      </div>
      <div class="col-lg-4 m-0 mb-2">
        <div class="bg-light rounded-3 p-2">
          <img
          [ngClass]="{
            'mobile-image': !isMobile(),
            'desktop-image': isMobile()
          }"
          [src]="imagePathLogoCandemasjor"
          class="rounded-circle border border-dark"
        />
        <p class="mb-0 fw-bolder">Septiembre 2021 - Marzo 2022 </p>
        <p class="mb-0">Desarrollador de cliente-servidor</p>
        <p class="mb-0">Autónomo</p>
        <p class="mb-0 fs-6">Alzira, Comunitat Valenciana, España</p>
      </div>
      </div>
      <div class="col-lg-4 m-0 mb-2">
        <div class="bg-light rounded-3 p-2">
          <img
          [ngClass]="{
            'mobile-image': !isMobile(),
            'desktop-image': isMobile()
          }"
          [src]="imagePathLogoRehubik"
          class="rounded-circle border border-dark"
        />
      <p class="mb-0 fw-bolder">Marzo 2021 - Junio 2021 </p>
        <p class="mb-0">Desarrollador multimedia</p>
        <p class="mb-0">Contrato de prácticas</p>
        <p class="mb-0 fs-6">Valencia, Comunitat Valenciana, España</p>
      </div>
      </div>
      <div class="col-lg-4 m-0 mb-2">
        <div class="bg-light rounded-3 p-2">
          <img
          [ngClass]="{
            'mobile-image': !isMobile(),
            'desktop-image': isMobile()
          }"
          [src]="imagePathLogoFesord"
          class="rounded-circle border border-dark"
        />
      <p class="mb-0 fw-bolder">Marzo 2021 - Junio 2021 </p>
        <p class="mb-0">Desarrollador web</p>
        <p class="mb-0">Contrato de prácticas</p>
        <p class="mb-0 fs-6">València, Comunitat Valenciana, España</p>
      </div>
      </div>
      <div class="col-lg-4 m-0 mb-2">
        <div class="bg-light rounded-3 p-2">
          <img
          [ngClass]="{
            'mobile-image': !isMobile(),
            'desktop-image': isMobile()
          }"
          [src]="imagePathLogoCerda"
          class="rounded-circle border border-dark"
        />
      <p class="mb-0 fw-bolder">Marzo 2019 - Junio 2019 </p>
        <p class="mb-0">Diseñador de páginas web</p>
        <p class="mb-0">Contrato de prácticas</p>
        <p class="mb-0 fs-6">Cerda, Comunidad Valenciana, España</p>
      </div>
      </div>
      <div class="col-lg-4 m-0 mb-2">
        <div class="bg-light rounded-3 p-2">
          <img
          [ngClass]="{
            'mobile-image': !isMobile(),
            'desktop-image': isMobile()
          }"
          [src]="imagePathLogoAppinformatico"
          class="rounded-circle border border-dark"
        />
      <p class="mb-0 fw-bolder">Mayo 2017 - Junio 2017 </p>
        <p class="mb-0">Técnico informático</p>
        <p class="mb-0">Contrato de prácticas</p>
        <p class="mb-0 fs-6">Alzira, Comunidad Valenciana, España</p>
      </div>
      </div>
      <div class="col-lg-4 m-0 mb-2">
        <div class="bg-light rounded-3 p-2">
          <img
          [ngClass]="{
            'mobile-image': !isMobile(),
            'desktop-image': isMobile()
          }"
          [src]="imagePathLogoAppinformatico"
          class="rounded-circle border border-dark"
        />
      <p class="mb-0 fw-bolder">Septiembre 2016 - Mayo 2016 </p>
        <p class="mb-0">Técnico informático</p>
        <p class="mb-0">Contrato de prácticas</p>
        <p class="mb-0 fs-6">Alzira, Comunidad Valenciana, España</p>
      </div>
      </div>
      <div class="col-lg-4 m-0 mb-2">
        <div class="bg-light rounded-3 p-2">
          <img
          [ngClass]="{
            'mobile-image': !isMobile(),
            'desktop-image': isMobile()
          }"
          [src]="imagePathLogoRaylight"
          class="rounded-circle border border-dark"
        />
      <p class="mb-0 fw-bolder">Septiembre 2012 - Marzo 2013 </p>
        <p class="mb-0">Diseño creativo</p>
        <p class="mb-0">Jornada temporal</p>
        <p class="mb-0 fs-6">Valencia, Comunidad Valenciana, España</p>
      </div>
      </div>
      <div class="col-lg-4 m-0 mb-2">
        <div class="bg-light rounded-3 p-2">
          <img
          [ngClass]="{
            'mobile-image': !isMobile(),
            'desktop-image': isMobile()
          }"
          [src]="imagePathLogoLaplazanet"
          class="rounded-circle border border-dark"
        />
      <p class="mb-0 fw-bolder">Septiembre 2012 - Marzo 2013 </p>
        <p class="mb-0">Diseño de productos gráficos</p>
        <p class="mb-0">Contrato de prácticas</p>
        <p class="mb-0 fs-6">Alaquas, Comunidad Valenciana, España</p>
      </div>
      </div>
      <div class="col-lg-4 m-0 mb-2">
        <div class="bg-light rounded-3 p-2">
          <img
          [ngClass]="{
            'mobile-image': !isMobile(),
            'desktop-image': isMobile()
          }"
          [src]="imagePathLogoHachcomunicacion"
          class="rounded-circle border border-dark"
        />
      <p class="mb-0 fw-bolder">Marzo 2012 - Marzo 2012 </p>
        <p class="mb-0">Diseño grafico</p>
        <p class="mb-0">Contrato de prácticas</p>
        <p class="mb-0 fs-6">Paterna, Comunidad Valenciana, España</p>
      </div>
      </div>
      <div class="col-lg-4 m-0 mb-2">
        <div class="bg-light rounded-3 p-2">
          <img
          [ngClass]="{
            'mobile-image': !isMobile(),
            'desktop-image': isMobile()
          }"
          [src]="imagePathLogoNevacom"
          class="rounded-circle border border-dark"
        />
      <p class="mb-0 fw-bolder">Diciembre 2010 - Enero 2011</p>
        <p class="mb-0">Diseño web</p>
        <p class="mb-0">Contrato de prácticas</p>
        <p class="mb-0 fs-6">Silla, Comunidad Valenciana, España</p>
      </div>
      </div>
    </div>
  </div>
</section>
