<nav class="navbar navbar-expand-lg navbar-dark bg-dark bg-body-tertiary">
    <div class="container-fluid justify-content-center">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" href="/">Inicio</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#experiencia" >Experiencia</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Educación</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Licencias y certificaciones</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Conocimientos y aptitudes</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Idiomas</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>